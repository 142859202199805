/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from '@/libs/axios.instance'
import authHeader from '@/libs/auth.header'

class TxnLogService {

	getTxnLogLists(queryParams) {
		const pars = {
			ref: queryParams.ref,
			pageNumber: queryParams.page,
		}
		return instance.get('/api/txn-log/view', { headers: authHeader(), params: pars })
	}
}

export default new TxnLogService()
