<template>
  <div>
    <div v-show="attachments" class="mb-2">
      <label class="d-block h5 mb-1">
        <feather-icon icon="PaperclipIcon" />
        {{ $t("Attachment") }}
      </label>
      <b-link
        v-for="(attachment, index) in attachments"
        :key="index"
        :href="attachment"
        target="_blank"
        class="cursor-pointer"
      >
        <b-img
          :src="attachment"
          fluid
          style="height: 80px; width: auto"
          class="mr-2 rounded"
        />
      </b-link>
    </div>
    <!-- Transaction History -->
    <label class="d-block h5 mb-1">{{ $t("Transaction history") }}</label>
    <b-table
      ref="refHistoryTransactiontable"
      class="position-relative table-white-space"
      :items="transaction_history"
      responsive
      :fields="historyTableColumn"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
    >
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(created_at)="data">
        {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
      </template>

      <!-- <template #cell(remark)="data">
				test
			</template> -->

      <!-- Column: Status -->
      <template #cell(status)="data">
        <div :class="`text-capitalize align-items-center d-flex`">
          <!-- <span :class="`dot bg-${resolveStatus(data.item.txn_status.id).variant}`" />{{ data.item.txn_status.name }}  -->

          <span
            :class="`dot bg-${resolveStatus(data.item.txn_status.id).variant}`"
          />{{ data.item.txn_status.name }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable, BImg, BLink } from "bootstrap-vue";
import {
  formatDateTime,
  resolveVBank,
  numberFormat,
  resolveCurrency,
} from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TxnLogService from "@/libs/txn-log.service";
import i18n from "@/libs/i18n";
import baseUrl from "@/libs/base-url";

export default {
  components: {
    BTable,
    BImg,
    BLink,
  },
  props: {
    transactionId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      transaction_history: [],
      historyTableColumn: [
        { label: "#", key: "index" },
        { label: this.$t("Last update"), key: "created_at" },
        { label: this.$t("Updated by"), key: "updated_by" },
        { label: this.$t("Remark"), key: "remark" },
        { label: this.$t("Status"), key: "status" },
      ],
      show: false,
      attachments: null,
    };
  },
  watch: {},
  mounted() {
    if (this.transactionId) {
      this.fetchTxnLogs(this.transactionId);
    }
  },
  methods: {
    fetchTxnLogs(transactionId) {
      TxnLogService.getTxnLogLists({ ref: transactionId }).then((response) => {
        if (response.data.code == "00") {
          this.transaction_history = response.data.data.txnLogs;
          this.attachments = this.showAttachments(response.data.data.bills);
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    showAttachments(images) {
      if (images.length > 0) {
        return images.map((image) => {
          return baseUrl + image.url;
        });
      }
    },
  },
  setup() {
    const resolveStatus = (stat) => {
      if (stat === 1) return { variant: "warning", label: i18n.t("Pending") };
      if (stat === 2) return { variant: "primary", label: i18n.t("Inprocess") };
      if (stat === 3) return { variant: "danger", label: i18n.t("Fail") };
      if (stat === 4) return { variant: "success", label: i18n.t("Success") };
    };

    return {
      formatDateTime,
      resolveVBank,
      numberFormat,
      resolveCurrency,
      resolveStatus,
    };
  },
};
</script>
<style lang="scss" scoped>
table.table-narrow td {
  padding: 10px !important;
}
</style>>