<template>
	<div>
		<b-modal
			id="modal-withdrawal-detail"
			cancel-variant="outline-secondary"
			:cancel-title="$t('Close')"
			ok-variant="primary"
			centered
			:title="$t('Withdrawal transaction detail')"
			size="lg"
			body-class="p-2"
			@hidden="resetModal()"
		>
			<b-overlay
				:show="show"
				variant="transparent"
				no-wrap
			/>
			<label class="d-block h5 mb-1">{{ $t('Member details') }}</label>
			<b-table-simple
				responsive
				bordered
				table-class="table-narrow table-white-space table-highlight"
				class="mb-2"
			>
				<b-tbody>
					<b-tr>
						<b-td class="table-td-header">{{ $t('Username') }}</b-td>
						<b-td>{{ withdrawal.username }}</b-td>
						<b-td class="table-td-header">{{ $t('Full Name') }}</b-td>
						<b-td>{{ withdrawal.fullName }}</b-td>
						<b-td class="table-td-header">{{ $t('Currency') }}</b-td>
						<b-td>{{ withdrawal.currency }}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
			<label class="d-block h5 mb-1">{{ $t('Withdrawal detail') }}</label>
			<b-table-simple
				responsive
				bordered
				table-class="table-narrow table-white-space table-highlight"
				class="mb-2"
			>
				<b-tbody>
					<b-tr>
						<b-td class="table-td-header">{{ $t('Transaction id') }}</b-td>
						<b-td>{{ withdrawal.transactionId }}</b-td>
						<b-td class="table-td-header">{{ $t('Payment type') }}</b-td>
						<b-td>{{ withdrawal.withdrawalType }}</b-td>
					</b-tr>
					<b-tr>
						<b-td class="table-td-header">{{ $t('Amount') }}</b-td>
						<b-td>{{ withdrawal.amount }}</b-td>
            <b-td class="table-td-header">{{` ${$t('Amount')} Net`}}</b-td>
            <b-td>{{ withdrawal.net_amount }}</b-td>

					</b-tr>
          <b-tr>
            <b-td class="table-td-header">{{ $t('Charge amount') }}</b-td>
            <b-td>{{ withdrawal.charged_amount }}</b-td>
          </b-tr>
					<b-tr>
						<!-- <b-td class="table-td-header">Attachment</b-td>
						<b-td>
							<b-link
								v-for="(attachment, index) in withdrawal.attachments"
								:key="index"
								:href="attachment"
								target="_blank"
							>
								<b-img
									:src="attachment"
									fluid
									width="50px"
								/>
							</b-link>
						</b-td> -->
						<b-td class="table-td-header">{{ $t('Member bank name') }}</b-td>
						<b-td colspan="3">{{ withdrawal.bank_name }}</b-td>
					</b-tr>
					<b-tr>
						<b-td class="table-td-header">{{ $t('Member account name') }}</b-td>
						<b-td>{{ withdrawal.member_bank_account_name }}</b-td>
						<b-td class="table-td-header">{{ $t('Member bank account no') }}</b-td>
						<b-td>{{ withdrawal.member_bank_account_no }}</b-td>
					</b-tr>
					<b-tr>
						<b-td class="table-td-header">{{ $t('Created at') }}</b-td>
						<b-td>{{ withdrawal.created_at }}</b-td>
						<b-td class="table-td-header">{{ $t('Updated at') }}</b-td>
						<b-td>{{ withdrawal.updated_at }}</b-td>
					</b-tr>
					<b-tr>
						<b-td class="table-td-header">{{ $t('Remark') }}</b-td>
						<b-td colspan="3">{{ withdrawal.message_refund }}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
			<txn-logs
				:transaction-id.sync="transactionId"
			/>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
	BOverlay, BLink, BImg, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge, BTableSimple, BTr, BTh, BTd, BTbody,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import { formatDateTime, resolveVBank, numberFormat, resolveCurrency } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import baseUrl from '@/libs/base-url'
import TxnLogs from '@/views/payments/txn-logs/TxnLogs.vue'

export default {
	components: {
		TxnLogs,
		BOverlay, BLink, BImg, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		BTableSimple, BTr, BTd, BTh, BTbody,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		withdrawalData: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			transactionId: null,
			withdrawal: {
				username: '',
				fullName: '',
				currency: '',
				transactionId: '',
				withdrawalType: '',
				amount: '',
				charged_amount: '',
				bank_name: '',
				member_bank_account_name: '',
				member_bank_account_no: '',
				created_at: '',
				updated_at: '',
				message_refund: '',
				attachments: '',
				transaction_history: [],
			},
			show: false,
		}
	},
	watch: {
		withdrawalData: {
			deep: true,
			handler(newVal) {
				if (newVal) {
					this.transactionId = newVal.ref
					this.withdrawal = {
						username: newVal.user ? newVal.user.username : '-',
						fullName: newVal.user ? newVal.user.name : '-',
						currency: newVal.currency || 'VND',
						transactionId: newVal.ref,
						withdrawalType: this.resolveType(newVal.type),
						amount: newVal.amount / 1000,
            net_amount: newVal.net_amount /1000,
						charged_amount: newVal.fee / 1000,
						attachments: this.showAttachments(newVal.bills),
						bank_name: newVal.user_bank_account ? newVal.user_bank_account.bank_name : '',
						member_bank_account_name: newVal.user.name,
						member_bank_account_no: newVal.user_bank_account ? newVal.user_bank_account.bank_account : '',
						created_at: formatDateTime(newVal.created_at).replace('.000000Z', ''),
						updated_at: formatDateTime(newVal.updated_at).replace('.000000Z', ''),
						message_refund: newVal.message_refund,
						transaction_history: [],
					}
				}
			}
		}
	},
	methods: {
		showAttachments(images) {
			if (images.length > 0) {
				return images.map(image => {
					return baseUrl + image.url
				})
			}
		},
		resetModal() {
			this.$emit('update:withdrawalData', null)
			this.withdrawal = {}
		},
	},
	setup() {

		const resolveType = (pt) => {
			if (pt === 1) return '1Click Pay'
			if (pt === 2) return 'WLBT'
			return 'n/a'
		}

		return {
			formatDateTime,
			resolveVBank,
			numberFormat,
			resolveCurrency,
			resolveType,
		}
	},
}
</script>
<style lang="scss" scoped>
table.table-narrow td {
	padding: 10px !important;
}
.dark-layout .table-highlight .table-td-header {
    background-color: #343d55 !important;
}
.table-highlight td.table-td-header {
    background-color: #f3f2f7;
}
</style>>